import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserCredential, Auth, onAuthStateChanged, User } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import {UserDataService} from "../user/user-data/user-data.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loggedInUserData: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(
    private http: HttpClient,
    private auth: Auth,
    private userDataService: UserDataService,
    ) {
    // Monitora mudanças no estado de autenticação
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.isLoggedInSubject.next(true);
        this.loggedInUserData.next(user);
      } else {
        this.isLoggedInSubject.next(false);
        this.loggedInUserData.next(null);
      }
    });
  }

  // Retorna um Observable com o estado de autenticação
  get isLoggedIn$() {
    return this.isLoggedInSubject.asObservable();
  }

  // Retorna um Observable com os dados do usuário autenticado
  get user$() {
    return this.loggedInUserData.asObservable();
  }

  /**
   * Salva os dados do usuário autenticado no estado e no localStorage (apenas no navegador).
   */
  saveUserData(user: UserCredential, userName: string, role: 'admin' | 'user' | 'professor' | 'coordination'): void {
    const userData = {
      uid: user.user?.uid || null,
      email: user.user?.email || null,
      displayName: userName || "Usuário",
      role: role,
    };

    this.loggedInUserData.next(user.user);
    this.isLoggedInSubject.next(true);

    if (this.isBrowser()) {
      this.userDataService.set(JSON.stringify(userData))
      // localStorage.setItem('user', JSON.stringify(userData));
    }
  }

  /**
   * Retorna os dados do usuário logado.
   */
  getUserData(): User | null {
    return this.loggedInUserData.getValue();
  }

  /**
   * Método para buscar o usuário autenticado (para evitar erro no UserGuard).
   */
  async getCurrentUser(): Promise<User | null> {
    return new Promise((resolve) => {
      const user = this.loggedInUserData.getValue();
      resolve(user);
    });
  }

  /**
   * Verifica se o usuário está logado.
   */
  isLoggedIn(): boolean {
    return this.isLoggedInSubject.getValue();
  }

  /**
   * Faz logout do usuário autenticado.
   */
  async logout(): Promise<void> {
    await this.auth.signOut();
    this.isLoggedInSubject.next(false);
    this.loggedInUserData.next(null);

    if (this.isBrowser()) {
      localStorage.removeItem('user');
    }
  }

  /**
   * Verifica se o código está rodando no navegador antes de acessar o localStorage.
   */
  private isBrowser(): boolean {
    return typeof window !== 'undefined' && typeof localStorage !== 'undefined';
  }
}
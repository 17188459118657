import {Component, ElementRef, OnInit} from '@angular/core';
import {LayoutService} from "../../../services/Layout/layout.service";
import {PanelMenuModule} from "primeng/panelmenu";
import {MenuItem} from "primeng/api";
import {NgClass} from "@angular/common";
import { PanelMenu } from 'primeng/panelmenu';
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    PanelMenuModule,
    NgClass,
    PanelMenu,
    RouterLink
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.less'
})
export class SidebarComponent implements OnInit {

  items: MenuItem[] | undefined;
  isActive: string = 'opened';

  constructor(
    private layoutService: LayoutService,
  ) {
  }

  ngOnInit(): void {
    this.layoutService.classState$.subscribe((state) => {
      this.isActive = state ? 'opened' : 'closed';
    });
    this.items = [
      {
        label: 'Geral',
        icon: 'pi pi-th-large',
        items: [
          { label: 'Painel', icon: 'pi pi-home', routerLink: ['/painel'] },
          { label: 'Calendário', icon: 'pi pi-calendar', routerLink: ['/painel/calendario'] },
          { label: 'Notificações', icon: 'pi pi-bell', routerLink: ['/painel/notificacoes'] }
        ]
      },
      {
        label: 'Acadêmico',
        icon: 'pi pi-graduation-cap',
        items: [
          { label: 'Alunos', icon: 'pi pi-book', routerLink: ['/painel/alunos'] },
          { label: 'Responsáveis', icon: 'pi pi-address-book', routerLink: ['/painel/responsaveis'] },
          { label: 'Turmas', icon: 'pi pi-users', routerLink: ['/painel/turmas'] },
          { label: 'Disciplinas', icon: 'pi pi-bookmark', routerLink: ['/painel/disciplinas'] },
          { label: 'Horários', icon: 'pi pi-clock', routerLink: ['/painel/horarios'] }
        ]
      },
      {
        label: 'Professores',
        icon: 'pi pi-user',
        items: [
          { label: 'Cadastro de Professores', icon: 'pi pi-user-plus', routerLink: ['/painel/cadastro-professores'] },
          { label: 'Atribuição de Aulas', icon: 'pi pi-sitemap', routerLink: ['/painel/atribuicao-aulas'] }
        ]
      },
      {
        label: 'Gestão de Conteúdo',
        icon: 'pi pi-folder-open',
        items: [
          { label: 'Material Didático', icon: 'pi pi-file', routerLink: ['/painel/material-didatico'] },
          { label: 'Tarefas e Atividades', icon: 'pi pi-pencil', routerLink: ['/painel/tarefas'] },
          { label: 'Provas', icon: 'pi pi-file-edit', routerLink: ['/painel/provas'] }
        ]
      },
      {
        label: 'Administração',
        icon: 'pi pi-cog',
        items: [
          { label: 'Usuários', icon: 'pi pi-users', routerLink: ['/painel/usuarios'] },
          { label: 'Configurações', icon: 'pi pi-sliders-h', routerLink: ['/painel/configuracoes'] },
          { label: 'Permissões', icon: 'pi pi-shield', routerLink: ['/painel/permissoes'] }
        ]
      },
      {
        label: 'Ferramentas',
        icon: 'pi pi-wrench',
        items: [
          { label: 'Encurtador', icon: 'pi pi-mobile', routerLink: ['/painel/criar-links'] },
          { label: 'Gerenciar Links', icon: 'pi pi-sitemap', routerLink: ['/painel/links'] },
          { label: 'Cadastro em Lotes', icon: 'pi pi-users', routerLink: ['/painel/groupRegister'] }
        ]
      },
      {
        label: 'Relatórios',
        icon: 'pi pi-chart-bar',
        items: [
          { label: 'Desempenho Acadêmico', icon: 'pi pi-chart-line', routerLink: ['/painel/desempenho-academico'] },
          { label: 'Frequência', icon: 'pi pi-calendar-check', routerLink: ['/painel/frequencia'] },
          { label: 'Atividades e Provas', icon: 'pi pi-list', routerLink: ['/painel/relatorio-atividades'] }
        ]
      }
    ];

  }

}

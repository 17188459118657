import {Component, OnInit} from '@angular/core';
import {UserDataService} from "../../../../services/user/user-data/user-data.service";
import {Button} from "primeng/button";
import {Menu} from "primeng/menu";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-user-info',
  standalone: true,
  imports: [
    Button,
    Menu
  ],
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.less'
})
export class UserInfoComponent implements OnInit {
  userName: string = '';
  hasNotifications: boolean = true;
  items: MenuItem[] | undefined;

  constructor(
    private userDataService: UserDataService,
  ) {
  }

  ngOnInit(): void {
    this.userName = this.userDataService.get().displayName;
    this.items = [
      {
        label: 'Editar Perfil',
        icon: 'pi pi-user-edit',
      },
      {
        label: 'Alterar Senha',
        icon: 'pi pi-lock',
      },
      {
        separator: true
      },
      {
        label: 'Sair',
        icon: 'pi pi-sign-out',
        command: () => this.logout()
      }
    ];

  }


  openNotifications() {
    console.log('Abrir painel de notificações');
  }


  private logout() {

  }
}

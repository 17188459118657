import { Injectable } from '@angular/core';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateProfile,
  User,
  UserCredential
} from '@angular/fire/auth';
import { Firestore, doc, getDoc, updateDoc } from '@angular/fire/firestore';
import { FirestoreService } from '../fire-store/firestore.service';
import { AuthService } from '../auth/auth.service';
import { environment } from 'environments/environment';

interface RegisterUser {
  name: string;
  role?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FireAuthService {
  private user: User | null = null;

  constructor(
    private auth: Auth,
    private firestoreService: FirestoreService,
    private authService: AuthService,
    private firestore: Firestore,
  ) {
    if (this.isBrowser()) {
      this.overrideLocalStorageSetItem();
    }
  }

  /**
   * Sobrescreve o método `localStorage.setItem` para adicionar logs durante o desenvolvimento.
   */
  private overrideLocalStorageSetItem(): void {
    if (!this.isBrowser() || environment.production) return;

    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key: string, value: string): void {
      if (key === 'role') {
        console.trace('Setting role in localStorage');
      }
      originalSetItem.apply(localStorage, [key, value]);
    };
  }

  /**
   * Verifica se o código está sendo executado em um navegador.
   */
  private isBrowser(): boolean {
    return typeof window !== 'undefined' && typeof localStorage !== 'undefined';
  }

  /**
   * Registra um novo usuário e armazena informações adicionais no Firestore.
   */
  public async register<T extends RegisterUser>(
    email: string,
    password: string,
    registerUser: T
  ): Promise<UserCredential> {
    try {
      const userCredential: UserCredential = await createUserWithEmailAndPassword(this.auth, email, password);

      const userData = {
        ...registerUser,
        email,
        role: registerUser.role || 'user',
      };

      await this.firestoreService.createDocument(`users/${userCredential.user.uid}`, userData);
      return userCredential;
    } catch (error: any) {
      let errorMessage = 'Erro desconhecido ao registrar o usuário.';
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = `O email ${email} já está em uso.`;
          break;
        case 'auth/invalid-email':
          errorMessage = `O email ${email} é inválido.`;
          break;
        case 'auth/weak-password':
          errorMessage = `A senha não é forte o suficiente.`;
          break;
      }
      console.error('Erro ao registrar o usuário:', errorMessage);
      throw new Error(errorMessage);
    }
  }

  /**
   * Obtém o nome do usuário armazenado no `localStorage`.
   */
  public getUserName(): string | null {
    if (this.isBrowser()) {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        try {
          const user = JSON.parse(storedUser);
          return user.displayName || null;
        } catch (error) {
          console.error('Erro ao parsear os dados do usuário no localStorage:', error);
        }
      }
    }
    return null;
  }

  /**
   * Escuta as mudanças no estado de autenticação do usuário.
   */
  public listenToAuthStateChanges(): void {
    authState(this.auth).subscribe((user: User | null) => {
    });
  }

  /**
   * Faz login de um usuário existente com email e senha.
   */
  public async signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
      const uid = userCredential.user.uid;

      const userDataFromDb = await this.fetchUserData(uid);

      if (userDataFromDb?.name) {
        await this.updateUserProfile(userDataFromDb.role, userDataFromDb.name);

        const userData = {
          uid,
          email: userCredential.user.email,
          displayName: userDataFromDb.name,
          role: userDataFromDb.role || 'user',
        };

        if (this.isBrowser()) {
          localStorage.setItem('user', JSON.stringify(userData));
        }
      }

      return userCredential;
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      throw error;
    }
  }

  /**
   * Faz logout do usuário autenticado.
   */
  public async signOut(): Promise<void> {
    if (this.isBrowser()) {
      localStorage.removeItem('user');
      localStorage.removeItem('userRole');
    }
    await this.auth.signOut();
  }

  /**
   * Atualiza o perfil do usuário logado.
   */
  public async updateUserProfile(role: string, name: string): Promise<void> {
    const user = this.auth.currentUser;
    if (user) {
      try {
        await updateProfile(user, { displayName: name });
      } catch (error) {
        console.error('Erro ao atualizar displayName:', error);
      }
    }
  }

  /**
   * Busca os dados do usuário no Firestore e atualiza o `localStorage`.
   */
  public async fetchAndUpdateUserRole(uid: string): Promise<void> {
    try {
      const userDocRef = doc(this.firestore, `users/${uid}`);
      const userSnapshot = await getDoc(userDocRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const updatedUser = {
          uid,
          email: this.auth.currentUser?.email || '',
          displayName: userData['name'] || 'Usuário',
          role: userData['role'] || 'user',
        };

        if (this.isBrowser()) {
          localStorage.setItem('user', JSON.stringify(updatedUser));
        }
      }
    } catch (error) {
      console.error('Erro ao buscar ou atualizar dados do Firestore:', error);
    }
  }

  /**
   * Busca os dados do usuário a partir do Firestore.
   */
  public async fetchUserData(uid: string): Promise<any> {
    try {
      return await this.firestoreService.getDocument<{ name: string; role: string }>(`users/${uid}`);
    } catch (error) {
      console.error('Erro ao buscar dados do usuário no Firestore:', error);
      throw error;
    }
  }

  /**
   * Envia um email para redefinição de senha.
   */
  public async sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }
}

import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuItem, MessageService} from "primeng/api";
import {Router} from "@angular/router";
import {ToolbarModule} from "primeng/toolbar";
import {Button} from "primeng/button";
import {LayoutService} from "../../../services/Layout/layout.service";
import {TitlePageService} from "../../../services/title/title-page.service";
import {UserInfoComponent} from "./user-info/user-info.component";

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    ToolbarModule,
    Button,
    CommonModule,
    UserInfoComponent
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.less',
  providers: [MessageService]

})
export class TopbarComponent implements OnInit {
  items: MenuItem[] | undefined;
  titleService = inject(TitlePageService);
  public pageTitle: string = '';

  constructor(
    private router: Router,
    private messageService: MessageService,
    private layoutService: LayoutService,
  ) {

  }

  ngOnInit() {
    this.titleService.title$.subscribe(title => {
      this.pageTitle = title;
    });
  }

  toggleClass() {
    this.layoutService.toggleClassState();
  }

  logout() {
    this.router.navigate(['/']); // Redireciona para a página inicial ou login
  }


}

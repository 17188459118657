<p-toolbar class="topbar">
  <div class="container-flex">
    <div class="root flex">
      <div class="col-6">

        <div class="p-toolbar-group-start">
          <p-button icon="pi pi-bars" [rounded]="true" [text]="true" (onClick)="toggleClass()"/>
          <div class="title-box">
            <div class="page-title-wrapper">
              <h1 class="page-title">
                {{ pageTitle }}
              </h1>
            </div>
          </div>

        </div>
      </div>
      <div class="col-6">
        <div class="p-toolbar-group-end">
          <app-user-info/>
        </div>
      </div>
    </div>
  </div>
</p-toolbar>

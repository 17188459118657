<div [ngClass]="isActive">
  <!--  <p-panelMenu [model]="items" styleClass="w-full md:w-20rem"/>-->

  <div class="sidebar-header">
    <div class="logo">
      <span class="logo-icon">
        <img src="assets/img/logoOsceia.png" alt="">
      </span>
      <span class="logo-text">Educandário Espírita Eurípedes Barsanulfo</span>
    </div>
  </div>

  <nav class="menu">
    <p-panelmenu [model]="items">
      <ng-template #item let-item>
        <a pRipple
           class="flex items-center p-menu-item-link cursor-pointer"
           [routerLink]="item.routerLink">
          <span [class]="item.icon"></span>
          <span class="ml-2">{{ item.label }}</span>
          @if (item.shortcut) {
            <span class="ml-auto border border-surface rounded bg-emphasis text-muted-color text-xs p-1">
          {{ item.shortcut }}
        </span>
          }
        </a>

      </ng-template>
    </p-panelmenu>


  </nav>
  <div class="footer">
    <p>Educandário Espírita Eurípedes Barsanulfo</p>
    <p>Feito com ❤️</p>
  </div>

</div>

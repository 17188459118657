<div class="user-header">
  <!-- Ícones de Notificação e Configurações -->
  <div class="user-header__icons">
    <p-button icon="pi pi-bell" class="p-button-rounded p-button-text p-button-ghost" [rounded]="true" (click)="openNotifications()">
      @if (hasNotifications) {<span class="notification-badge"></span>}
    </p-button>    

    <p-button icon="pi pi-cog" class="p-button-rounded p-button-text p-button-ghost" [rounded]="true" (click)="menu.toggle($event)" ></p-button>
    <p-menu #menu [model]="items" [popup]="true" />

  </div>

  <!-- Nome do Usuário e Avatar -->
  <div class="user-header__profile">
    <div class="user-header__info">
      <span class="user-header__name">{{ userName }}</span>
      <span class="user-header__role">Admin</span>
    </div>
    <div class="user-header__avatar"></div>
  </div>
</div>


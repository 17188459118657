import {Injectable, inject} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitlePageService {

  private titleSubject = new BehaviorSubject<string>('Default Title');
  title$ = this.titleSubject.asObservable();

  private title = inject(Title);

  setTitle(newTitle: string): void {
    this.titleSubject.next(newTitle);
    this.title.setTitle(newTitle);
  }
}

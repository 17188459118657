import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  arrayUnion,
  doc,
  Firestore,
  getDocFromServer,
  setDoc,
  updateDoc,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(private firestore: Firestore) {}

  async createDocument(docPath: string, data: any): Promise<void> {
    if (data.role && data.role === 'admin') {
      throw new Error('A criação de administradores não é permitida pelo frontend.');
    }
    const docReference = doc(this.firestore, docPath);
    await setDoc(docReference, data);
  }

  async getCollection<T>(collectionPath: string): Promise<T[]> {
    const collectionRef = collection(this.firestore, collectionPath);
    const querySnapshot = await getDocs(collectionRef);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as T));
  }

  async searchCollection(collectionPath: string, field?: string, value?: string): Promise<any[]> {
    try {
      const ref = collection(this.firestore, collectionPath);
      let q;
      if (field && value) {
        q = query(ref, where(field, '>=', value), where(field, '<=', value + '\uf8ff'));
      } else {
        q = ref;
      }
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        return [];
      }
      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error(`Erro ao buscar na coleção "${collectionPath}":`, error);
      return [];
    }
  }

  async addIdToArray(docPath: string, field: string, id: string): Promise<void> {
    const ref = doc(this.firestore, docPath);
    await updateDoc(ref, {
      [field]: arrayUnion(id),
    });
  }

  async addDocument(collectionPath: string, data: any): Promise<string> {
    const collectionRef = collection(this.firestore, collectionPath);
    const doc = await addDoc(collectionRef, data);
    return doc.id;
  }

  async getDocument<T>(docPath: string): Promise<T | null> {
    const docReference = doc(this.firestore, docPath);
    const docSnap = await getDocFromServer(docReference);
    if (docSnap.exists()) {
      console.log('Documento carregado:', docSnap.data());
      return docSnap.data() as T;
    }else
    console.warn('Documento não encontrado:', docPath);
    return null;
  }

  async updateDocument(collectionPath: string, data: any): Promise<void> {
    const docRef = doc(this.firestore, collectionPath);
    await updateDoc(docRef, data);
  }

  async deleteDocument(collectionPath: string, id: string): Promise<void> {
    if (!id) {
      throw new Error('ID inválido ou indefinido.');
    }
  
    const docRef = doc(this.firestore, collectionPath, id);
    await deleteDoc(docRef);
  }  
}

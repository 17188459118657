export const environment = {
  firebase: {
    apiKey: "AIzaSyAIa0ZoAJ0zvXZwqYDKHVQn6fwcU0vSfqU",
    authDomain: "escola-site-d3bc4.firebaseapp.com",
    projectId: "escola-site-d3bc4",
    storageBucket: "escola-site-d3bc4.appspot.com",
    messagingSenderId: "1005052436511",
    appId: "1:1005052436511:web:e4fd704d58040a88a7f52a",
    measurementId: "G-2HXWQVB74G"
  },
  production: false
};



import { Injectable } from '@angular/core';
import { FirestoreService } from '../../../services/fire-store/firestore.service';
import { Links } from '../models/links.model';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  private collectionPath = 'links'; // Caminho da coleção no Firestore

  constructor(private firestoreService: FirestoreService) {}

  /**
   * Lista todos os links da coleção.
   * @returns Observable contendo a lista de links.
   */
  listarLinks(): Observable<Links[]> {
    return from(this.firestoreService.getCollection<Links>(this.collectionPath));
  }

 /**
   * Busca um link pelo ID.
   * @param id O ID do link.
   * @returns Observable contendo o link encontrado ou lança erro caso não exista.
   */
 buscarLinkPorId(id: string): Observable<Links> {
  const docPath = `${this.collectionPath}/${id}`;
  return from(this.firestoreService.getDocument<Links>(docPath)).pipe(
    map((link) => {
      if (!link) {
        throw new Error(`Link com ID ${id} não encontrado.`);
      }
      return link;
    })
  );
}

  /**
   * Adiciona um novo link à coleção.
   * @param linktreeData Dados do link a serem adicionados.
   * @returns Promise com o ID do documento adicionado.
   */
  async adicionarLink(linktreeData: Links): Promise<string> {
    try {
      const docId = await this.firestoreService.addDocument(this.collectionPath, linktreeData);
      console.log(`Link adicionado com sucesso: ${docId}`);
      return docId;
    } catch (error) {
      console.error('Erro ao adicionar link:', error);
      throw new Error('Não foi possível adicionar o link. Por favor, tente novamente.');
    }
  }

  /**
   * Atualiza um link existente pelo ID.
   * @param id ID do link a ser atualizado.
   * @param links Dados atualizados do link.
   * @returns Promise resolvida quando a atualização for concluída.
   */
  async atualizarLink(id: string, links: Links): Promise<void> {
    try {
      const docPath = `${this.collectionPath}/${id}`;
      await this.firestoreService.updateDocument(docPath, links);
      console.log(`Link atualizado com sucesso: ${id}`);
    } catch (error) {
      console.error(`Erro ao atualizar o link com ID ${id}:`, error);
      throw new Error('Não foi possível atualizar o link. Por favor, tente novamente.');
    }
  }

  /**
   * Remove um link pelo ID.
   * @param id ID do link a ser removido.
   * @returns Promise resolvida quando a remoção for concluída.
   */
  async removerLink(id: string): Promise<void> {
    try {
      const docPath = `${this.collectionPath}/${id}`;
      await this.firestoreService.deleteDocument('links', docPath);
      console.log(`Link removido com sucesso: ${id}`);
    } catch (error) {
      console.error(`Erro ao remover o link com ID ${id}:`, error);
      throw new Error('Não foi possível remover o link. Por favor, tente novamente.');
    }
  }
}
